import { parseISO } from "date-fns";
import * as React from "react";
import sites from "../../articles.json";
import LatestArticles from "../components/LatestArticles";
import Layout from "../components/layout.js";
import PageNavigation from "../components/PageNavigation";

const latestArticles = sites.map((site) => site.articles).flat();
latestArticles.sort(
  (a, b) => parseISO(b.modified).valueOf() - parseISO(a.modified).valueOf()
);

const articlesPerPage = 30;
const pageCount = Math.min((latestArticles.length / articlesPerPage) | 0, 10);

const IndexPage = () => {
  return (
    <Layout>
      <LatestArticles
        header="最新記事"
        latestArticles={latestArticles.slice(0, articlesPerPage)}
      ></LatestArticles>
      <PageNavigation
        rootPagePath={"/"}
        eachPagePath={"/article/page"}
        pageIndex={1}
        pageCount={pageCount}
      ></PageNavigation>
    </Layout>
  );
};

export default IndexPage;
